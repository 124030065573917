import React, {useEffect, useReducer, useState} from "react";
import {getProp} from "../../../common/util/util-helpers";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, formatMoney, getUser} from "../../../util/util";
import Resources from "../../../data/services/resources";
import {createResource, getResource, updateResource} from "../../../data/actions/resource";
import {useSelector} from "react-redux";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import LocalStorage from "../../../util/localStorage";
import {numberWithCommasToBack} from "../../../util/util-formaters";
import WorkOrdersLaborPartsPrices from "./work-orders-labor-parts-prices";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../common/components/resource-table";
import NoRecords from "../../../common/components/no-records-found/no-records";
import {
    ArrowPathIcon,
    CheckCircleIcon, InformationCircleIcon,
    PencilIcon,
    PlusIcon,
    TrashIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import Buttons from "../../../common/components/buttons";
import PageTabWithFooter from "../../../common/components/layout/layout-components/page/page-tab-with-footer";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Button from "../../../common/components/button";
import InfoParagraph from "../../../common/components/info-paragraph";
import ModalDefault from "../../../common/components/modal/modal-default";
import Env from "../../../util/env";
import {getJWT} from "../../../common/util/util-auth";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, READ_PERM} from "../../../common/util/util-consts";
import axios from "axios";
// import WorkOrdersLaborPartsAiModal from "./work-orders-labor-parts-ai-modal";
import Bot from "../../../assets/images/bot.png";
// import ErrorCodeHelpAiModal from "./error-code-help-ai-modal";
import Tippy from "@tippyjs/react";
import {XMarkIcon} from "@heroicons/react/20/solid";
// import EmailEstimate from "./email-estimate";
import {showGlobalModal} from "../../../data/actions/ui";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import FieldOptions from "../../../common/components/fields/field-options";
import moment from "moment";
import InvoiceExpenseDetailsDialog from "../../../common/components/modal/invoice-expense-details-dialog";
import {getThirdResource} from "../../../data/actions/thirdResource";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import WorkOrdersLaborPartsAiModal
    from "../work-orders-single-view/work-orders-labor-parts-tab/work-orders-labor-parts-ai-modal";
import {getSecondResource} from "../../../data/actions/secondResource";

export default function WorkOrdersLaborPartsTemplateCreate({dispatch, ID, translate}) {

    /** Constants
     ================================================================= */
    const isInvoiced = false;
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const thirdResource = useSelector(state => state.thirdResource);
    const dialogResource = useSelector(state => state.dialogResource);

    const [data, setData] = useState([])
    const dataObject = getProp(resource, "data", [])
    const dataSettings = getProp(resource, "data.Settings", [])
    const [dataMisc, setDataMisc] = useState([])
    const dataUnit = getProp(resource, "data.Unit", {
        Type: "Truck",
        Year: "Unspecified",
        Manufacturer: "Unspecified",
        Model: "Unspecified",
    })

    const dataParts = data.map(item => {
        item['Parts'].map(it => {
            it.TotalAmount = numberWithCommasToBack(it.PartPrice) * (Number(it.PartQty) ?? 0);
            return it;
        })

        return item['Parts']
    });

    const totalPriceParts = dataParts.reduce((memo, it) => {
        memo += it.reduce((partMemo, part) => {
            partMemo += part.TotalAmount

            return partMemo
        }, 0)

        return memo;
    }, 0);

    const dataLabor = data.map(item => {
        item['Labor'].map(it => {
            it.TotalAmount = numberWithCommasToBack(it.LaborPrice) * (Number(it.LaborHours) ?? 0);
            return it;
        });

        return item['Labor']
    })

    const totalPriceLabor = dataLabor.reduce((memo, it) => {
        memo += it.reduce((laborMemo, labor) => {
            laborMemo += labor.TotalAmount

            return laborMemo
        }, 0)
        return memo;
    }, 0);

    const dataMiscValues = dataMisc.map(item => {

        item.TotalAmount = numberWithCommasToBack(item.MiscPrice * (Number(item.MiscQty) ?? 0));
        return item;
    });

    const dataMiscTotal = dataMiscValues.reduce((memo, it) => {
        memo += it.TotalAmount

        return memo
    }, 0)

    const defaultLaborItem = {LaborHours: "1", LaborPrice: formatMoney(dataSettings?.DefaultLaborPrice) ?? ""}

    /** State
     ================================================================= */
    const [fieldsAction, setFieldsAction] = useState([]);
    const [initialComplaintFields, setInitialComplaintFields] = useState(getInitialComplaintFields(dataObject, isInvoiced));

    const [isPartsFromDialogOpen, setIsPartsFormDialogOpen] = useState(false);
    const [isLaborFromDialogOpen, setIsLaborFormDialogOpen] = useState(false);
    const [isMiscFormDialogOpen, setIsMiscFormDialogOpen] = useState(false);
    const [aiData, setAiData] = useState(undefined);
    const [aiDialog, setAiDialog] = useState(false);
    const [aiDialogSingle, setAiDialogSingle] = useState(false);
    const [aiSingleIndex, setAiSingleIndex] = useState(null);
    const [aiLoading, setAILoading] = useState(false);
    const [aiItemLoading, setAIItemLoading] = useState([]);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [selectedReportItem, setSelectedReportItem] = useState(undefined);
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [partCreateIndex, setPartCreateIndex] = useState(null);
    const [laborCreateIndex, setLaborCreateIndex] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [additionalAiInfo, setAdditionalAiInfo] = useState("");
    const [itemsChecked, setItemsChecked] = useState([]);
    const [isErrorCodeHelpModalOpen, setIsErrorCodeHelpModalOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [selectedInvoiceExpenseItem, setSelectedInvoiceExpenseItem] = useState({});
    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
    const [isInvoiceExpenseDialogOpen, setIsInvoiceExpenseDialogOpen] = useState(false);

    const handleInputChange = (name, value, index, fields) => {
        setFieldsAction(prevState => ({
            ...prevState,
            [index]: FieldsManager.updateField(fields, name, value)
        }))
        setIsDirty(true);
    }
    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: getUser(),
            resource: Resources.WorkOrderTemplates,
            query: {WorkOrderTemplateID: ID}
        }))
    }

    const handleConfirmDeletePartClick = (item) => {

        let newData = data;
        newData[item.ind]['Parts'].splice(item.dataIndex, 1);

        if (item.ChildPartID !== null) {
            newData[item.ind]['Parts'] = newData[item.ind]['Parts'].filter((it) => it.ParentPartID !== item.ChildPartID)
        }

        closeConfirmDialog();
        setIsDirty(true);
    }

    const handleConfirmDeleteLaborClick = (item) => {

        let newData = data;
        newData[item.ind]['Labor'].splice(item.dataIndex, 1);

        closeConfirmDialog();
        setIsDirty(true);
    }

    const handleConfirmDeleteMiscClick = (item) => {

        let newData = dataMisc;
        newData.splice(item.dataIndex, 1);

        closeConfirmDialog();
        setIsDirty(true);
    }

    const handleCancelClick = () => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmCancelClick(),
            type: "danger",
            content:
                `Are you sure you want to discard all changes?`
            ,
            title: translate("Delete unsaved changes"),
            buttonLabel: translate("btn.delete")
        })
    }
    const handleConfirmCancelClick = () => {

        // refresh data

        closeConfirmDialog();

        setIsDirty(false);
    }
    /** UI Events
     ================================================================= */
    const handleCreateLaborClick = (index) => {
        setIsLaborFormDialogOpen(true);
        defaultLaborItem.ind = index;
        defaultLaborItem.dataIndex = data[index]['Labor'].length;
        setSelectedItem(defaultLaborItem);
        setLaborCreateIndex(index)
    }

    const handleCreatePartClick = (index) => {
        setIsPartsFormDialogOpen(true);
        setSelectedItem(undefined);
        setPartCreateIndex(index)
    }

    const handleEditPartClick = (item) => {
        setIsPartsFormDialogOpen(true);
        setSelectedItem(item);

    }

    const handleEditLaborClick = (item) => {
        setIsLaborFormDialogOpen(true);
        setSelectedItem(item);
    }

    const handleCreateMiscClick = () => {
        setIsMiscFormDialogOpen(true);
        setSelectedItem(undefined);
    }

    const handleEditMiscClick = (item) => {
        setIsMiscFormDialogOpen(true);
        setSelectedItem(item);
    }

    const handleInitialComplaintInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(initialComplaintFields, name, value)
        setInitialComplaintFields(newFields)
        setIsDirty(true)
    }

    const handleLaborInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value);

        // if (name === "TaxID") {
        //     if(value) {
        //         fields.Tax.value = value.label
        //     } else {
        //         fields.TaxAmount.value = 1
        //         fields.Tax.value = null
        //     }
        // }
        //
        // fields.TaxAmount.value = fields.TaxID?.value?.amount ?? fields.TaxAmount.value

        fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.LaborPrice.value) * Number(fields.LaborHours.value) ?? 0));

        return fields;
    }

    const handleMiscInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value);

        // if (name === "TaxID") {
        //     if(value) {
        //         fields.Tax.value = value.label
        //     } else {
        //         fields.TaxAmount.value = 1
        //         fields.Tax.value = null
        //     }
        // }
        //
        // fields.TaxAmount.value = fields.TaxID?.value?.amount ?? fields.TaxAmount.value

        fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.MiscPrice.value) * Number(fields.MiscQty.value) ?? 0));

        return fields;
    }

    const handlePartsInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value);

        // if (name === "TaxID") {
        //     if(value) {
        //         fields.Tax.value = value.label
        //     } else {
        //         fields.TaxAmount.value = 1
        //         fields.Tax.value = null
        //     }
        // }
        //
        // fields.TaxAmount.value = fields.TaxID?.value?.amount ?? fields.TaxAmount.value

        fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.PartPrice.value) * Number(fields.PartQty.value) ?? 0));

        if (name === "PartName" && value) {
            fields.ProductServiceID.value = value.value

            fields.PartNameLabel.value = value.label

            if (value.DirtyProductServiceID) {
                fields.DirtyProductServiceID.value = value.DirtyProductServiceID
                fields.DirtyProductPrice.value = value.DirtyProductPrice
            }

            if (value.InherentProductServiceID) {
                fields.InherentProductServiceID.value = value.InherentProductServiceID
                fields.InherentProductPrice.value = value.InherentProductPrice
            }

            if (value.DirtyProductService) {
                fields.DirtyProductService.value = value.DirtyProductService
            }

            if (value.InherentProductService) {
                fields.InherentProductService.value = value.InherentProductService
            }

            if (value?.IncomePrice) {
                fields.PartPrice.value = value?.IncomePrice;
            }

            if (!fields?.PartQty?.value) {
                fields.PartQty.value = 1;
            }

            fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.PartPrice.value) * Number(fields.PartQty.value) ?? 0));
        }

        if (name === "HasWarranty") {
            fields.UpToMileage.type = !value ? "hidden" : "integer_or_empty"
            fields.UpToMonths.type = !value ? "hidden" : "integer_or_empty"
        }
        return fields;
    }

    const handleDeletePartClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeletePartClick(item),
            type: "danger",
            content:
                `Are you sure you want to delete item ${item.PartName}?`
            ,
            title: translate("text.confirm_delete_part"),
            buttonLabel: translate("btn.delete")
        })
    }


    const handleDeleteLaborClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteLaborClick(item),
            type: "danger",
            content:
                `Are you sure you want to delete item ${item.Description}?`
            ,
            title: translate("text.confirm_delete_labor"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleDeleteMiscClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteMiscClick(item),
            type: "danger",
            content:
                `Are you sure you want to delete item ${item.Description}?`
            ,
            title: translate("text.confirm_delete_misc_item"),
            buttonLabel: translate("btn.delete")
        })
    }

    const getInherentOrDirtyPrice = (item) => {
        if (item) {
            return item;
        } else
            return 0;
    }
    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const scrollErrorIntoViewItems = (fields, i) => {
        for (const [key, value] of Object.entries(fields)) {
            if (value.errorMessage) {
                const firstErrorInput = document.getElementById(`itemIndex${i}`);

                if (firstErrorInput) {
                    firstErrorInput.parentElement.scrollIntoView({block: "center", behavior: "instant"});
                    return true;
                }
            }
        }

        return false;
    }
    const scrollErrorIntoViewFieldsItems = (array) => {
        for (let i = 0; i < array.length; i++) {
            scrollErrorIntoViewItems(array[i], i)
        }

        return false;
    }

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const submit = () => {
        let dataItems = {};
        let dataForSubmit = {};

        data.map((item, itemIndex) => {
            let actionItems = Object.values(fieldsAction[itemIndex]);

            let newObject = {};

            newObject[itemIndex] = {
                ActionItem: null,
                ActionItemStatusID: null,
                AssignedContactID: null,
                CustomCodeID: null,
                ServiceBayID: null,
                ActionItemDescription: null,
                ShowItemAsSubTotal: null
            }

            actionItems.map((it, index) => {
                    newObject[itemIndex][it.name] = it.value;
                }
            )

            dataForSubmit[itemIndex] = {};

            let actionFieldsValidated = fieldsAction;
            actionFieldsValidated[itemIndex] = FieldsManager.validateFields(fieldsAction[itemIndex])
            if (FieldsManager.checkFieldsForErrors(actionFieldsValidated[itemIndex])) {
                Object.assign(dataForSubmit[itemIndex], FieldsManager.getFieldKeyValues(fieldsAction[itemIndex]))
            } else {
                setFieldsAction(actionFieldsValidated)
            }

            dataForSubmit[itemIndex]['Parts'] = item['Parts']
            dataForSubmit[itemIndex]['Labor'] = item['Labor']
        })


        dataItems.Items = dataForSubmit;
        dataItems.Description = initialComplaintFields.Description.value;
        dataItems.Notes = initialComplaintFields.Notes.value;
        dataItems.Misc = Object.assign({}, dataMisc);

        if (Object.values(fieldsAction).every(item => FieldsManager.checkFieldsForErrors(item))) {
            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: dataItems,
                query: {},
                notificationMessage: translate("message.wo_template_created"),
                resource: Resources.WorkOrderTemplates,
                piggyResource: Resources.WorkOrderTemplates,
                successMessage: "Work order template created"
            }));
        } else {
            scrollErrorIntoViewFieldsItems(Object.values(fieldsAction))
        }

        setIsDirty(false)
    }

    const ShopSuppliesTotalAmount = () => {
        switch (dataSettings?.ShopSuppliesSurchargeTypeID) {
            case 1:
                return 0;
            case 2:
                return totalPriceLabor * (dataSettings?.ShopSuppliesAmount - 1).toFixed(2);
            case 3:
                return (totalPriceLabor + totalPriceParts) * (dataSettings?.ShopSuppliesAmount - 1).toFixed(2);
            default:
                return 0;
        }
    }

    const TotalAmountSupplies = () => {
        let suppliesAmount = ShopSuppliesTotalAmount()

        if (dataSettings.ShopSuppliesMinAmount && suppliesAmount < dataSettings.ShopSuppliesMinAmount && totalPriceLabor) {
            suppliesAmount = dataSettings.ShopSuppliesMinAmount
        }

        if (dataSettings.ShopSuppliesMaxAmount && suppliesAmount > dataSettings.ShopSuppliesMaxAmount) {
            suppliesAmount = dataSettings.ShopSuppliesMaxAmount
        }

        return suppliesAmount.toFixed(2)
    }

    const handleSetEstimateClick = () => {
        setSelectedItem(dataObject);
        setIsEmailModalOpen(true);
    }

    const toggleAiDialog = (item = null) => {
        if (!aiDialog) {
            setAILoading(true)
            getAiRecon().then((res) => {
                setAiData(res.data);
                setAILoading(false)
            });
        } else {
            setAdditionalAiInfo("")
        }
        setAiDialog(!aiDialog);
    }

    const toggleAiDialogSingle = (index) => {
        if (!aiDialogSingle) {
            setAILoading(true)
            setAiSingleIndex(index)
            let Complaint = fieldsAction[index].ActionItem.value
            getAiReconRefreshItem(Complaint).then((res) => {
                res.data.ActionItems[0].Complaint = Complaint;
                setAiData(res.data);
                setAILoading(false);
            })

        } else {
            setAdditionalAiInfo("")
        }
        setAiDialogSingle(!aiDialogSingle);
    }

    const askAIAgain = () => {
        setAILoading(true)
        getAiRecon().then((res) => {
            setAiData(res.data);
            setAILoading(false)
        });
    }

    const askAIAgainSingle = (index) => {
        setAILoading(true)
        setAiSingleIndex(index)
        let Complaint = fieldsAction[index].ActionItem.value
        getAiReconRefreshItem(Complaint).then((res) => {
            res.data.ActionItems[0].Complaint = Complaint;
            setAiData(res.data);
            setAILoading(false);
        })
    }

    const AIRefresh = (item, index) => {
        let newAiData = aiData;
        setAIItemLoading(prevState => ({
            ...prevState,
            [index]: true
        }))

        getAiReconRefreshItem(item).then((res) => {
            newAiData.ActionItems = aiData?.ActionItems.map((value, actionIndex) => {
                if (actionIndex === index) {
                    let newResult = res.data.ActionItems[0];
                    newResult.Complaint = item;
                    return newResult
                } else return value
            })
            setAIItemLoading(prevState => ({
                ...prevState,
                [index]: false
            }))
        })
        setAiData(newAiData);
    }

    const fillFromAi = () => {
        let newAiData = aiData
        newAiData?.ActionItems.map(((it, itIndex) => {
            it.isChecked = itemsChecked[itIndex].itemChecked.value

            if (it.isChecked) {
                let newData = data;
                newData[itIndex] = {
                    "Parts": [],
                    "Labor": []
                }

                let newFields = fieldsAction
                newFields[itIndex] = {}
                newFields[itIndex].ActionItem = it?.Complaint
                newFields[itIndex].ActionItemDescription = it?.Correction

                setFieldsAction(prevState => ({
                    ...prevState,
                    [itIndex]: getFieldsAction(newFields[itIndex], isInvoiced)
                }))


                it?.Labor.map((laborItem, index) => {
                    let length = newData[itIndex]['Labor'].length
                    newData[itIndex]['Labor'][length] = {};
                    newData[itIndex]['Labor'][length].Description = laborItem.LaborDescription;
                    newData[itIndex]['Labor'][length].LaborHours = laborItem?.LaborHours;
                    newData[itIndex]['Labor'][length].LaborPrice = dataSettings?.DefaultLaborPrice ?? 0;
                })
            } else {
                delete data[itIndex]
            }
        }))

        const elemsToDelete = data.length - newAiData?.ActionItems?.length
        data.splice(data.length - elemsToDelete, elemsToDelete);
        setIsDirty(true)
        toggleAiDialog()
    }

    const fillFromAiSingle = (itIndex) => {
        let newAiData = aiData
        newAiData?.ActionItems.map(((it, actionItemIndex) => {
            it.isChecked = itemsChecked[actionItemIndex].itemChecked.value

            if (it.isChecked) {
                let newData = data;
                newData[itIndex] = {
                    "Parts": [],
                    "Labor": []
                }

                let newFields = fieldsAction
                newFields[itIndex] = {}
                newFields[itIndex].ActionItem = it?.Complaint
                newFields[itIndex].ActionItemDescription = it?.Correction

                setFieldsAction(prevState => ({
                    ...prevState,
                    [itIndex]: getFieldsAction(newFields[itIndex], isInvoiced)
                }))


                it?.Labor.map((laborItem, index) => {
                    let length = newData[itIndex]['Labor'].length
                    newData[itIndex]['Labor'][length] = {};
                    newData[itIndex]['Labor'][length].Description = laborItem.LaborDescription;
                    newData[itIndex]['Labor'][length].LaborHours = laborItem?.LaborHours;
                    newData[itIndex]['Labor'][length].LaborPrice = dataSettings?.DefaultLaborPrice ?? 0;
                })
            } else {
                delete data[itIndex]
            }
        }))
        setIsDirty(true)
        toggleAiDialogSingle()
    }

    const getAiRecon = () => {
        return axios.get(
            Env.getWorkOrderAiUrl('prompt', {
                input: initialComplaintFields?.Description.value ?? "",
                make: dataUnit?.Manufacturer,
                model: dataUnit?.Model,
                year: dataUnit?.Year,
                type: dataUnit?.Type,
                engine: dataUnit?.Engine ?? "",
                additional: additionalAiInfo ?? ""
            }),
            {
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                },
            }
        )
    }

    const getAiReconRefreshItem = (item, index) => {
        return axios.get(
            Env.getWorkOrderAiUrl('prompt', {
                input: item ?? "",
                make: dataUnit?.Manufacturer,
                model: dataUnit?.Model,
                year: dataUnit?.Year,
                type: dataUnit?.Type,
                engine: dataUnit?.Engine ?? "",
                additional: additionalAiInfo ?? ""
            }),
            {
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                },
            }
        )
    }

    const handleInvoiceExpenseClick = (item = null) => {
        setSelectedInvoiceExpenseItem(item);
        setIsInvoiceExpenseDialogOpen(true);
    }

    const handleViewReportClick = (selectedItem) => {
        setSelectedReportItem(selectedItem);
        setIsReportDialogOpen(true);
    }
    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (data) {
            data.map((it, index) => {

                setFieldsAction(prevState => ({
                    ...prevState,
                    [index]: getFieldsAction(it, isInvoiced)
                }))
            })
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            setFieldsAction(prevState => ({
                ...prevState,
                [data.length]: getFieldsAction({}, isInvoiced)
            }))
        }
    }, [data.length]);

    useEffect(() => {
        setInitialComplaintFields(getInitialComplaintFields(dataObject, isInvoiced))
    }, [dataObject.Description])
    /** Component Body
     ================================================================= */
    return (
        <PageTabWithFooter
            isLoading={isLoading}
            buttons={
                [
                    {
                        className: "btn btn-primary",
                        onClick: () => submit(),
                        disabled: !isDirty,
                        label: translate("btn.save")
                    },
                    {
                        className: "btn btn-outline",
                        disabled: !isDirty,
                        onClick: () => {
                            handleCancelClick();
                        },
                        label: translate("btn.cancel")
                    },
                ]
            }
        >
            <div className="py-2 space-y-4 w-full max-w-7xl mx-auto min-w-[60rem] mb-10 mt-4">
                {!!isInvoiced && (
                    <div className="my-2">
                        <InfoParagraph
                            className=""
                            message={translate("field.WOisInvoiced")}
                        />
                    </div>
                )}

                <div className="flex flex-wrap items-center gap-4">
                    {/*<span>*/}
                    {/*<div className="flex items-center gap-4">*/}
                    {/*        <button*/}
                    {/*            onClick={() => setIsErrorCodeHelpModalOpen(true)}*/}
                    {/*            className="btn btn-outline text-sky-600 border-sky-600"*/}
                    {/*        >*/}
                    {/*            <img src={Bot} alt="bot" className={"w-5 h-5 mr-3"}/> Need AI help identifying the diagnostic code?*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</span>*/}
                    <div className="ml-auto flex items-center gap-4">
                        <Button appearance={'secondary'} addClass={"flex ml-auto"} disabled={isInvoiced}
                                onClick={() => {
                                    toggleAiDialog()
                                }}><img src={Bot} alt="bot" className={"w-5 h-5 mr-3"}/> {translate("btn.ask_ai")}
                        </Button>

                        {/*<Button*/}
                        {/*    appearance={'primary'}*/}
                        {/*    addClass={"flex"}*/}
                        {/*    disabled={isInvoiced}*/}
                        {/*    onClick={() => {*/}
                        {/*        handleSetEstimateClick();*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {translate("btn.preview_estimate")}*/}
                        {/*</Button>*/}
                    </div>
                </div>

                <div className={"grid grid-cols-6 gap-5"}>
                    {fieldsToHtml(Object.values(Object.assign({}, initialComplaintFields)), translate, handleInitialComplaintInputChange)}
                </div>
                <WorkOrdersLaborPartsPrices
                    isResourceLoading={isLoading}
                    totalPriceParts={totalPriceParts}
                    totalPriceLabor={totalPriceLabor}
                    // shopSupplies={dataSettings}
                    dataObject={dataObject}
                    dataMiscTotal={dataMiscTotal}
                />

                {/*{!!dataObject.IsTaxExempt && (*/}
                {/*    <InfoBar type='warning'>*/}
                {/*        <div>*/}
                {/*            {'This customer is tax-exempt. Reason is ' + dataObject.TaxExemptReason ?? ""}*/}
                {/*        </div>*/}
                {/*    </InfoBar>*/}
                {/*)}*/}

                {data.map((it, index) => {
                    let dataPartsTable = it.Parts;
                    dataPartsTable.forEach((element) => {
                        element.ind = index
                    });

                    const dataPartsTableCalculate = dataPartsTable.map((it) => {
                        // it.CalculatedPartsPrice = numberWithCommasToBack(it.PartPrice) * Number(it.PartQty) ?? 0;
                        it.CalculatedPartsPrice = numberWithCommasToBack(it.PartPrice) * (Number(it.PartQty) ?? 0);
                        return it;

                    });

                    const dataPartsTableTotal = dataPartsTableCalculate.reduce((memo, it) => {
                        memo += it.CalculatedPartsPrice
                        return memo
                    }, 0)

                    let dataLaborTable = it.Labor;
                    dataLaborTable.forEach((element) => {
                        element.ind = index
                    });

                    const dataLaborTableCalculate = dataLaborTable.map((it) => {
                        // it.CalculatedLaborPrice = numberWithCommasToBack(it.LaborPrice) * Number(it.LaborHours) ?? 0;
                        it.CalculatedLaborPrice = numberWithCommasToBack(it.LaborPrice) * (Number(it.LaborHours) ?? 0);
                        return it;
                    });

                    const dataLaborTableTotal = dataLaborTableCalculate.reduce((memo, it) => {
                        memo += it.CalculatedLaborPrice
                        return memo
                    }, 0)

                    return (
                        <Card>
                            {/*Action fields*/}
                            <div className={"flex items-center justify-between"}>
                                <h3 className={"text-lg font-bold "}>#{index + 1}</h3>
                                <Button appearance={'secondary'} addClass={"flex mr-auto ml-4"} disabled={isInvoiced}
                                        onClick={() => {
                                            toggleAiDialogSingle(index);
                                        }}><img src={Bot} alt="bot" className={"w-5 h-5"}/>
                                </Button>
                                <button disabled={isInvoiced}
                                        className={"flex"}
                                        onClick={() => {
                                            delete data[index];
                                            forceUpdate();
                                            setIsDirty(true)
                                        }
                                        }
                                >
                                    <XCircleIcon className={"w-8 h-8"}/>
                                </button>
                            </div>
                            <div className={"grid grid-cols-12 gap-x-6 gap-y-4"} id={`itemIndex${index}`}>
                                {fieldsToHtml(Object.values(Object.assign({}, fieldsAction[index])), translate, (name, value) => handleInputChange(name, value, index, fieldsAction[index]), {
                                    CustomCodeID: {
                                        api: 'api/' + Resources.WorkOrdersCustomCodes,
                                        query: {},
                                        searchMap: (it) => ({
                                            label: it.CustomCode,
                                            value: it.CustomCodeID,
                                        })
                                    },
                                    ServiceBayID: {
                                        api: 'api/' + Resources.ServiceBaysQuick,
                                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                        searchMap: (item) => ({
                                            value: item.ServiceBayID,
                                            label: item.BayNumber
                                        })
                                    },
                                    AssignedContactID: {
                                        api: 'api/' + Resources.Contacts,
                                        query: {},
                                        searchMap: (it) => ({
                                            label: `${it.FirstName} ${it.LastName}`,
                                            value: it.ContactID,
                                        })
                                    },
                                    ActionItemStatusID: {
                                        1: "Open",
                                        2: "Rejected",
                                        3: "In progress",
                                        4: "Completed"
                                    }
                                })}
                            </div>


                            {/*Parts*/}
                            <div className={"space-y-4"}>
                                <WorkOrdersLaborPartsPrices
                                    totalPriceParts={dataPartsTableTotal}
                                    totalPriceLabor={dataLaborTableTotal}
                                    isItem={true}
                                />

                                <ResourceTable
                                    data={dataPartsTable}
                                    fields={getPartsFields({}, handleViewReportClick)}

                                    translate={translate}
                                    options={
                                        {
                                            behaviour: {
                                                rowSelect: false,
                                                canAdjustWidth: false,
                                                hasMenu: false

                                            },
                                            style: {
                                                condensed: true,
                                                isGPUAccelerated: true,
                                                horizontalLines: true,
                                                verticalLines: true
                                            }
                                        }
                                    }
                                    actions={[
                                        {
                                            title: () => translate('btn.edit'),
                                            visible: () => !isInvoiced,
                                            action: (item) => handleEditPartClick(item),
                                            icon: () => PencilIcon
                                        },
                                        {
                                            title: () => translate('btn.delete'),
                                            visible: (it) => !isInvoiced && (it.ParentPartID === null || it.ParentPartID === undefined),
                                            action: (item) => handleDeletePartClick(item),
                                            icon: () => TrashIcon
                                        }
                                    ]}

                                    onRowClick={!isInvoiced && handleEditPartClick}
                                />

                                <NoRecords
                                    show={!dataPartsTable.length}
                                    addClass="p-4"
                                    title="No Parts"
                                    text={translate("text.add_part_button")}
                                />

                                <Buttons
                                    buttons={[
                                        {
                                            label: translate("btn.add_part"),
                                            type: "primary",
                                            disabled: isInvoiced,
                                            onClick: () => handleCreatePartClick(index),
                                            className: "btn btn-primary flex ml-auto"

                                        }
                                    ]}
                                />
                            </div>

                            {/*Labor*/}
                            <div className={"space-y-2"}>
                                <ResourceTable
                                    data={dataLaborTable}
                                    fields={getLaborFields({}, dataObject)}

                                    translate={translate}
                                    options={
                                        {
                                            behaviour: {
                                                rowSelect: false,
                                                canAdjustWidth: false,
                                                hasMenu: false

                                            },
                                            style: {
                                                condensed: true,
                                                isGPUAccelerated: true,
                                                horizontalLines: true,
                                                verticalLines: true
                                            }
                                        }
                                    }

                                    actions={[
                                        {
                                            title: () => translate('btn.edit'),
                                            visible: () => !isInvoiced,
                                            action: (item) => handleEditLaborClick(item),
                                            icon: () => PencilIcon
                                        },
                                        {
                                            title: () => translate('btn.delete'),
                                            visible: () => !isInvoiced,
                                            action: (item) => handleDeleteLaborClick(item),
                                            icon: () => TrashIcon
                                        }
                                    ]}

                                    onRowClick={!isInvoiced && handleEditLaborClick}
                                />
                                <NoRecords
                                    show={!dataLaborTable.length}
                                    addClass="p-4"
                                    title="No Labor"
                                    text={translate("text.add_labor_button")}
                                />
                                <Buttons
                                    buttons={[
                                        {
                                            label: translate("btn.add_labor"),
                                            type: "primary",
                                            disabled: isInvoiced,
                                            onClick: () => handleCreateLaborClick(index),
                                            className: "btn btn-primary flex ml-auto"
                                        }
                                    ]}
                                />

                            </div>
                        </Card>
                    )
                })}

                <button className={"btn btn-primary flex mx-auto"} disabled={isInvoiced} onClick={
                    () => {
                        let newData = data;
                        newData[data.length] = {
                            "Parts": [],
                            "Labor": []
                        }
                        forceUpdate()
                        setIsDirty(true)
                    }
                }> Add new item
                </button>

                <Card>
                    <div className={"flex justify-center text-lg"}>Misc. charges</div>
                    <div>
                        <Buttons
                            buttons={[
                                {
                                    label: translate("btn.add_misc"),
                                    type: "primary",
                                    disabled: isInvoiced,
                                    onClick: () => handleCreateMiscClick(),
                                    className: "btn btn-primary flex ml-auto mb-4"

                                }
                            ]}
                        />

                        <ResourceTable
                            data={dataMisc}
                            fields={getMiscFields()}

                            translate={translate}
                            options={
                                {
                                    behaviour: {
                                        rowSelect: false,
                                        canAdjustWidth: false,
                                        hasMenu: false

                                    },
                                    style: {
                                        condensed: true,
                                        isGPUAccelerated: true,
                                        horizontalLines: true,
                                        verticalLines: true
                                    }
                                }
                            }
                            actions={[
                                {
                                    title: () => translate('btn.edit'),
                                    visible: () => !isInvoiced,
                                    action: (item) => handleEditMiscClick(item),
                                    icon: () => PencilIcon
                                },
                                {
                                    title: () => translate('btn.delete'),
                                    visible: (it) => !isInvoiced,
                                    action: (item) => handleDeleteMiscClick(item),
                                    icon: () => TrashIcon
                                }
                            ]}

                            onRowClick={!isInvoiced && handleEditMiscClick}
                        />

                        <NoRecords
                            show={!dataMisc.length}
                            addClass="p-4"
                            title="No Misc. charges"
                        />
                    </div>

                    {/*<button className={"btn btn-outline flex mx-auto"} onClick={*/}
                    {/*    () => {handleCreateMiscClick()}}*/}
                    {/*> {translate("btn.add_misc")}*/}
                    {/*</button>*/}
                </Card>

                <ModalConfirm
                    show={confirmDialogData?.isOpen ?? false}
                    type={confirmDialogData?.type}
                    text={confirmDialogData?.content}
                    title={confirmDialogData?.title}
                    onConfirm={confirmDialogData?.onClick}
                    closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                    buttonLabel={confirmDialogData?.buttonLabel}
                    translate={translate}
                    onClose={closeConfirmDialog}
                />

                <ModalSaveResource
                    show={isPartsFromDialogOpen}
                    initialFocusID="PartName"
                    fields={getPartsFields(selectedItem, handleViewReportClick, dataObject)}
                    title={selectedItem ? translate("modal_heading.edit_part_item") : translate("modal_heading.add_part")}
                    onClose={() => {
                        setIsPartsFormDialogOpen(false);
                    }}
                    handleInputChange={handlePartsInputChange}
                    onSubmit={(params) => {
                        if (params?.PartNameLabel) {
                            params.PartName = params.PartNameLabel;
                        }

                        if (!!params) {
                            if (selectedItem) {
                                let newData = data;

                                if (selectedItem.ParentPartID !== undefined) {
                                    params.ParentPartID = selectedItem.ParentPartID
                                }

                                params.ChildPartID = selectedItem.ChildPartID
                                newData[selectedItem.ind]['Parts'][selectedItem.dataIndex] = params

                                setIsPartsFormDialogOpen(false);
                                setIsDirty(true)
                            } else {

                                let newData = data;

                                let ParamsFirst = JSON.parse(JSON.stringify(params));

                                let lengthFirst = newData[partCreateIndex]['Parts'].length
                                newData[partCreateIndex]['Parts'][lengthFirst] = ParamsFirst

                                ParamsFirst.ChildPartID = lengthFirst

                                let DirtyParams = JSON.parse(JSON.stringify(params));

                                if (DirtyParams.DirtyProductServiceID) {
                                    DirtyParams.ProductServiceID = DirtyParams.DirtyProductServiceID
                                    delete DirtyParams.InherentProductServiceID
                                    delete DirtyParams.InherentProductService

                                    if (DirtyParams.DirtyProductService) {
                                        DirtyParams.PartName = DirtyParams.DirtyProductService;
                                        DirtyParams.PartPrice = getInherentOrDirtyPrice(DirtyParams.DirtyProductPrice);//Pass price as param to a function getInherentOrDirtyPrice(); example getInherentOrDirtyPrice(DirtyParams.DirtyProductPrice);
                                        DirtyParams.ParentPartID = lengthFirst
                                    }
                                    let length = newData[partCreateIndex]['Parts'].length

                                    newData[partCreateIndex]['Parts'][length] = DirtyParams
                                }

                                let InherentParams = JSON.parse(JSON.stringify(params));

                                if (InherentParams.InherentProductServiceID) {
                                    InherentParams.ProductServiceID = InherentParams.InherentProductServiceID
                                    delete InherentParams.DirtyProductService
                                    delete InherentParams.DirtyProductServiceID

                                    if (InherentParams.InherentProductService) {
                                        InherentParams.PartName = InherentParams.InherentProductService;
                                        InherentParams.PartPrice = getInherentOrDirtyPrice(InherentParams.InherentProductPrice);//Pass price as param to a function getInherentOrDirtyPrice(); example getInherentOrDirtyPrice(InherentParams.InherentProductPrice);
                                        InherentParams.ParentPartID = lengthFirst
                                    }
                                    let length = newData[partCreateIndex]['Parts'].length
                                    newData[partCreateIndex]['Parts'][length] = InherentParams
                                }

                                setIsPartsFormDialogOpen(false);
                                setIsDirty(true)


                            }

                            setSelectedItem(params); // in case of server error save form data
                        }
                    }}
                    addFieldContainerClass={"col-span-6"}
                    addBodyClass={"pb-12 text-tm-gray-900"}
                    resource={resource}
                    metadata={getSelects()}
                    translate={translate}
                />

                <ModalSaveResource
                    show={isLaborFromDialogOpen}
                    initialFocusID="LaborPrice"
                    fields={getLaborFields(selectedItem, dataObject)}
                    title={selectedItem ? translate("modal_heading.edit_labor_item") : translate("modal_heading.add_labor_item")}
                    onClose={() => {
                        setIsLaborFormDialogOpen(false);
                    }}
                    handleInputChange={handleLaborInputChange}
                    onSubmit={(params) => {
                        if (!!params) {
                            if (selectedItem?.LaborHours) {
                                let newData = data;
                                newData[selectedItem.ind]['Labor'][selectedItem.dataIndex] = params

                                setIsLaborFormDialogOpen(false);
                                setIsDirty(true)

                            } else {
                                let newData = data;
                                let length = newData[laborCreateIndex]['Labor'].length
                                newData[laborCreateIndex]['Labor'][length] = params

                                setIsLaborFormDialogOpen(false);
                                setIsDirty(true)
                            }

                            setSelectedItem(params); // in case of server error save form data
                        }
                    }}
                    addFieldContainerClass={"col-span-6"}
                    addBodyClass={"pb-12 text-tm-gray-900"}
                    metadata={getSelects()}
                    resource={resource}
                    translate={translate}
                />

                <ModalDefault
                    show={aiDialog}
                    limitHeight={true}
                    widthClass={'max-w-6xl'}
                    title={translate('text.ai_dialog')}

                    onButtonClick={fillFromAi}
                    buttonLabel={translate('Apply selected suggestions')}

                    customButtonsHTML={(
                        <div>
                            <button
                                className={`btn btn-primary mr-2`}
                                onClick={() => {
                                    askAIAgain();
                                }}
                            >
                                Ask again
                            </button>
                        </div>
                    )}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={toggleAiDialog}
                >
                    <WorkOrdersLaborPartsAiModal
                        aiData={aiData}
                        aiLoading={aiLoading}
                        aiItemLoading={aiItemLoading}
                        translate={translate}
                        additionalAiInfo={additionalAiInfo}
                        setAdditionalAiInfo={setAdditionalAiInfo}
                        setItemsChecked={setItemsChecked}
                        dataUnit={dataUnit}
                        getAiReconRefreshItem={(item, index) => AIRefresh(item, index)}
                    />
                </ModalDefault>

                    <ModalDefault
                        show={aiDialogSingle}
                        limitHeight={true}
                        widthClass={'max-w-6xl'}
                        title={translate('text.ai_dialog')}

                        onButtonClick={() => fillFromAiSingle(aiSingleIndex)}
                        buttonLabel={translate('Apply selected suggestions')}

                        customButtonsHTML={(
                            <div>
                                <button
                                    className={`btn btn-primary mr-2`}
                                    onClick={() => {
                                        askAIAgainSingle(aiSingleIndex);
                                    }}
                                >
                                    Ask again
                                </button>
                            </div>
                        )}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={toggleAiDialogSingle}
                    >
                        <WorkOrdersLaborPartsAiModal
                            aiData={aiData}
                            aiLoading={aiLoading}
                            aiItemLoading={aiItemLoading}
                            translate={translate}
                            additionalAiInfo={additionalAiInfo}
                            setAdditionalAiInfo={setAdditionalAiInfo}
                            setItemsChecked={setItemsChecked}
                            dataUnit={dataUnit}
                            getAiReconRefreshItem={(item, index) => AIRefresh(item, index)}
                        />
                    </ModalDefault>

                {/*    <ModalDefault*/}
                {/*        show={isErrorCodeHelpModalOpen}*/}
                {/*        limitHeight={true}*/}
                {/*        widthClass={'max-w-3xl'}*/}
                {/*        title={translate('text.error_code_help')}*/}

                {/*        closeButtonLabel={translate('btn.Close')}*/}
                {/*        onClose={() => setIsErrorCodeHelpModalOpen(false)}*/}
                {/*    >*/}
                {/*        <ErrorCodeHelpAiModal*/}
                {/*            dataUnit={dataUnit}*/}
                {/*            translate={translate}*/}
                {/*        />*/}
                {/*    </ModalDefault>*/}

                <ModalSaveResource
                    show={isMiscFormDialogOpen}
                    initialFocusID=""
                    fields={getMiscFields(selectedItem)}
                    title={selectedItem ? translate("btn.edit_misc") : translate("btn.add_misc")}
                    onClose={() => {
                        setIsMiscFormDialogOpen(false);
                    }}
                    handleInputChange={handleMiscInputChange}
                    onSubmit={(params) => {
                        if (!!params) {
                            let newData = dataMisc;
                            if (selectedItem) {
                                newData[selectedItem.dataIndex] = params

                                setIsMiscFormDialogOpen(false);
                                setIsDirty(true)
                            } else {
                                let length = newData.length
                                newData[length] = params

                                setIsMiscFormDialogOpen(false);
                                setIsDirty(true)
                            }

                            setSelectedItem(params); // in case of server error save form data
                        }
                    }}
                    addFieldContainerClass={"col-span-6"}
                    addBodyClass={"pb-12 text-tm-gray-900"}
                    metadata={getSelects()}
                    resource={resource}
                    translate={translate}
                />


                {/*    <EmailEstimate*/}
                {/*        selectedItem={selectedItem}*/}
                {/*        WorkOrder={WorkOrder}*/}
                {/*        show={isEmailModalOpen}*/}
                {/*        onClose={() => {*/}
                {/*            setIsEmailModalOpen(false);*/}
                {/*            setSelectedItem(undefined);*/}
                {/*        }}*/}
                {/*        ID={ID}*/}
                {/*        translate={translate}*/}
                {/*    />*/}

            </div>

            <ResourceTableDialog
                widthClass={'max-w-6xl'}
                show={isReportDialogOpen}
                dialogResource={dialogResource}
                resource={Resources.ProductServiceReport}
                title={'Inventory report'}
                dispatch={dispatch}
                isFooterVisible={true}
                onView={(item) => {
                    handleInvoiceExpenseClick(item)
                }}
                hasViewPerm={checkPerm(Resources.ExpenseExpense, READ_PERM) && checkPerm(Resources.InvoicesInvoice, READ_PERM)}
                defaultAction={(item) => {
                    if (item.Type === 1 && !checkPerm(Resources.ExpenseExpense, READ_PERM)) {
                        return false;
                    }

                    if (item.Type !== 1 && !checkPerm(Resources.InvoicesInvoice, READ_PERM)) {
                        return false;
                    }

                    handleInvoiceExpenseClick(item)
                }}
                limit={50}
                fields={{
                    Date: new Field('Date', '', [], false, 'date', {
                        omitSort: true
                    }),
                    Description: new Field('Description', '', [''], false, 'textarea', {omitSort: true}),
                    Type: new Field('Type', '', [''], false, 'custom', {
                        render: (it) => {
                            return it.Type === 1 ? 'Bought' : 'Sold'
                        },
                        omitSort: true
                    }),
                    FromTo: new Field('FromTo', '', [''], false, 'custom', {
                        render: (it) => it.FromTo
                    }),
                    Amount: new Field('Amount', '', [''], false, 'money', {omitSort: true}),
                    Qty: new Field('Qty', '', [''], false, 'text', {omitSort: true}),
                    // TaxID: new Field('TaxID', '', [''], false, 'select-search', {omitSort: true}),
                    TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {omitSort: true})
                }}

                fieldsFilter={{
                    StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                    EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                    OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4'}, {
                        isClearable: true
                    }),
                }}

                htmlBefore={(
                    <div className={"p-2 text-lg"}>
                        Current profit for the filtered
                        records: {formatMoney(dialogResource?.data?.TotalTotalAmount ?? 0)}
                    </div>
                )}

                tableKey="Name"
                defaultQuery={{id: selectedReportItem?.ProductServiceID ?? null}}
                onClose={() => setIsReportDialogOpen(false)}
                translate={translate}
                selects={{
                    OrganizationID: {
                        api: 'api/' + Resources.OrganizationsQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.OrganizationID,
                            label: item.LegalName,
                            Organization: item
                        })
                    }
                }}
                htmlAfter={() => {
                    return <ModalDefault
                        show={isInvoiceExpenseDialogOpen}
                        widthClass={'max-w-5xl w-screen'}
                        title={"Details"}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={() => setIsInvoiceExpenseDialogOpen(false)}
                    >
                        <InvoiceExpenseDetailsDialog
                            resource={thirdResource}
                            isLoading={thirdResource?.isLoading}
                            translate={translate}
                            dispatch={dispatch}
                            showLoadReferenceLinks={true}

                            disableComments={true}
                            canDeletePayment={false}
                            disableAddReference={true}
                            disableTransactionImport={true}

                            // Data events
                            onFetchData={() => {
                                if (selectedInvoiceExpenseItem?.ID) {
                                    dispatch(getThirdResource({
                                        user: LocalStorage.get('user'),
                                        query: {
                                            [selectedInvoiceExpenseItem.Type === 1 ? "ExpenseID" : "InvoiceID"]: selectedInvoiceExpenseItem.ID
                                        },
                                        resource: selectedInvoiceExpenseItem.Type === 1 ? Resources.ExpenseExpense : Resources.InvoicesInvoice
                                    }))
                                }
                            }}
                        />
                    </ModalDefault>
                }}
            />
        </PageTabWithFooter>
    )
}

const getInitialComplaintFields = (item = {}, isInvoiced) => {
    const fieldTemplates = {
        Description: new Field('Description', '', [''], isInvoiced, 'textarea', {addContainerClass: "col-span-3"}),
        Notes: new Field('Notes', '', [''], isInvoiced, 'textarea', {addContainerClass: "col-span-3"}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getFieldsAction = (item = {}, isInvoiced) => {
    const fieldTemplates = {
        ActionItem: new Field('ActionItem', '', ['empty'], isInvoiced, 'textarea', {addContainerClass: "col-span-6"}),
        ActionItemDescription: new Field('ActionItemDescription', '', [''], isInvoiced, 'textarea', {addContainerClass: "col-span-6"}),
        ActionItemStatusID: new Field('ActionItemStatusID', 1, ['empty'], isInvoiced, 'select', {addContainerClass: "col-span-2"}),
        CustomCodeID: new Field('CustomCodeID', '', [], isInvoiced, 'select-search', {
            addContainerClass: "col-span-2",
            isClearable: true
        }),
        ServiceBayID: new Field('ServiceBayID', '', [], isInvoiced, 'select-search', {
            addContainerClass: "col-span-3",
            isClearable: true
        }),
        AssignedContactID: new Field('AssignedContactID', '', [''], isInvoiced, 'select-search', {
            addContainerClass: "col-span-3",
            isClearable: true
        }),
        ShowItemAsSubTotal: new Field('ShowItemAsSubTotal', '', [], isInvoiced, 'checkbox', {
            addContainerClass: "col-span-2",
        }),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getPartsFields = (item = {}, handleViewReportClick, dataObject) => {
    // item.CalculatedPartsPrice = (numberWithCommasToBack(item.PartPrice) * (Number(item.PartQty) ?? 0) * (Number(item.TaxAmount) ?? 0).toString())
    item.TotalAmount = formatMoney(item.TotalAmount)

    const fieldTemplates = {
        ProductServiceID: new Field('ProductServiceID', '', [''], false, 'hidden', {hideTable: true}),
        DirtyProductServiceID: new Field('DirtyProductServiceID', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        PartNameLabel: new Field('PartNameLabel', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        InherentProductServiceID: new Field('InherentProductServiceID', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        DirtyProductService: new Field('DirtyProductService', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        InherentProductService: new Field('InherentProductService', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        DirtyProductPrice: new Field('DirtyProductPrice', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        InherentProductPrice: new Field('InherentProductPrice', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        PartName: new Field('PartName', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full",
            render: (it) => {
                return (
                    <CellButton
                        className="block leading-5 py-0.5 -mx-2 px-2 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary text-primary-shade font-btn hover:bg-primary-transparent focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                        onClick={() => {
                            handleViewReportClick(it);
                        }}
                    >
                        <div>{it.PartName}</div>
                    </CellButton>
                )
            },
            fieldOptions: (it) => {
                it.ProductServiceID = item.ProductServiceID

                if (it.value) {
                    if (item.PartName !== it.value.value) {
                        it.ProductServiceID = it.value.value
                    }
                }

                return (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                onClick: () => {handleViewReportClick(it)},
                                isVisible: !!it.value
                            }
                        ]}
                    />
                )
            }
        }),
        PartQty: new Field('PartQty', '', ['empty'], false, 'integer', {addContainerClass: "col-span-3"}),
        PartPrice: new Field('PartPrice', '', ['empty'], false, 'money', {
            addContainerClass: "col-span-3",
            render: (it) => genericMoneyFormatter(it.PartPrice)
        }),

        // TaxID: new Field('TaxID', '', [''], false, 'select-search', {addContainerClass: "col-span-3", hideTable: true}, {isClearable: true}),
        // TaxAmount: new Field('TaxAmount', '1', [''], false, 'hidden', {
        //     addContainerClass: "col-span-3",
        //     hideTable: true,
        //     hideDialog: true
        // }),
        // Tax: new Field('Tax', '', [''], false, 'text', {addContainerClass: "col-span-3", hideDialog: true}),
        TotalAmount: new Field('TotalAmount', '', [''], true, 'money', {
            addContainerClass: "col-span-3",
            omitSort: true,
            render: (it) => genericMoneyFormatter(numberWithCommasToBack(it.TotalAmount))
        }),
        HasWarranty: new Field('HasWarranty', '', [''], false, 'checkbox', {
            label: "HasWarranty",
            hasActiveBadge: true,
            render: (it) => {
                return (
                    <div className={"flex justify-center"}>{!!it.HasWarranty
                        ?
                        <Tippy
                            content={(!!it.UpToMileage ? "Up tp mileage: " + it.UpToMileage : "") + " " + (!!it.UpToMonths ? "Up tp months: " + it.UpToMonths : "")}>
                            <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                        </Tippy>
                        :
                        <>
                            <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                        </>
                    }</div>

                )
            },
            addContainerClass: "col-span-3"
        }),
        UpToMileage: new Field('UpToMileage', '0', [''], false, !item.HasWarranty ? "hidden" : 'integer_or_empty', {
            addContainerClass: "col-span-3",
            hideTable: true
        }),
        UpToMonths: new Field('UpToMonths', '0', [''], false, !item.HasWarranty ? "hidden" : 'integer_or_empty', {
            addContainerClass: "col-span-3",
            hideTable: true
        }),
        Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}, {rows: 8})
    }

    let filledFields = fillFieldsFromData(fieldTemplates, item)

    // if (!item.PartName) {
    //     filledFields.TaxID.value =  dataObject?.IsTaxExempt === 0 ? {label: dataObject?.Tax, value: dataObject?.TaxID, amount: dataObject?.TaxAmount} : null;
    //     filledFields.Tax.value = dataObject?.Tax;
    // }

    return filledFields
}

const getLaborFields = (item = {}, dataObject) => {
    // item.CalculatedLaborPrice = numberWithCommasInputChange((numberWithCommasToBack(item.LaborPrice) * Number(item.LaborHours) ?? 0).toString())
    item.TotalAmount = formatMoney(item.TotalAmount)
    const fieldTemplates = {
        Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}, {rows: 4}),
        LaborHours: new Field('LaborHours', '', ['empty'], false, 'float', {
            addContainerClass: "col-span-3",
            addTableHeaderClass: "mr-auto"
        }),
        LaborPrice: new Field('LaborPrice', '', ['empty'], false, 'money', {
            addContainerClass: "col-span-3",
            addTableHeaderClass: "mr-auto"
        }),
        // TaxID: new Field('TaxID', '', [''], false, 'select-search', {addContainerClass: "col-span-3", hideTable: true}, { isClearable: true }),
        // TaxAmount: new Field('TaxAmount', 1, [''], false, 'float', {
        //     addContainerClass: "col-span-3",
        //     hideTable: true,
        //     hideDialog: true
        // }),
        // Tax: new Field('Tax', '', [''], false, 'text', {addContainerClass: "col-span-3", hideDialog: true}),
        TotalAmount: new Field('TotalAmount', '', [''], true, 'money', {
            addContainerClass: "col-span-3",
            omitSort: true,
            render: (it) => genericMoneyFormatter(numberWithCommasToBack(it.TotalAmount))
        }),
        Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}, {rows: 4})
    }

    let filledFields = fillFieldsFromData(fieldTemplates, item)

    // if (!item.Description) {
    //     filledFields.TaxID.value =  dataObject?.IsTaxExempt === 0 ? {label: dataObject?.Tax, value: dataObject?.TaxID, amount: dataObject?.TaxAmount} : null;
    //     filledFields.Tax.value = dataObject?.Tax;
    // }
    //
    // if (filledFields.TaxID.value) {
    //     filledFields.TaxAmount.value = filledFields.TaxID?.value?.amount ?? filledFields.TaxAmount.value
    //     filledFields.TotalAmount.value = formatMoney((numberWithCommasToBack(filledFields.LaborPrice.value) * Number(filledFields.LaborHours.value) ?? 0) * (Number(filledFields.TaxAmount.value) ?? 1).toString());
    // }

    return filledFields
}

const getMiscFields = (item = {}) => {
    const fieldTemplates = {
        Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
        MiscQty: new Field('MiscQty', '', ['empty'], false, 'float', {addContainerClass: "col-span-3"}),
        MiscPrice: new Field('MiscPrice', '', ['empty'], false, 'float', {addContainerClass: "col-span-3"}),
        // TaxID: new Field('TaxID', '', [''], false, 'select-search', {addContainerClass: "col-span-3", hideTable: true}, {isClearable: true}),
        // Tax: new Field('Tax', '', [''], false, 'text', {addContainerClass: "col-span-3", hideDialog: true}),
        // TaxAmount: new Field('TaxAmount', 1, [''], false, 'float', {
        //     addContainerClass: "col-span-3",
        //     hideTable: true,
        //     hideDialog: true
        // }),
        TotalAmount: new Field('TotalAmount', '', [''], true, 'money', {addContainerClass: "col-span-3",}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

function getSelects() {
    return {
        PartName: {
            api: 'api/' + Resources.WorkOrderPartsQuick,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.ProductService + ' (SKU: ' + it.SKU + ")" + " (OEM: " + it.OEMNumber + ")",
                    value: it.ProductServiceID,
                    DirtyProductServiceID: it.DirtyProductServiceID,
                    DirtyProductService: it.DirtyProductService,
                    InherentProductServiceID: it.InherentProductServiceID,
                    InherentProductService: it.InherentProductService,
                    InherentProductPrice: it.InherentProductPrice,
                    DirtyProductPrice: it.DirtyProductPrice,
                    IncomePrice: it?.IncomePrice ?? ""
                })
            }
        },
        // TaxID: {
        //     api: 'api/' + Resources.AccountingTaxesQuick,
        //     query: {},
        //     searchMap: (it) => {
        //         return ({
        //             label: it.Tax,
        //             value: it.TaxID,
        //             amount: it.Amount
        //         })
        //     }
        // }
    }
}





