import React, {useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getUser} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {deleteResource, getResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {getLookup, getProp} from "../../../common/util/util-helpers";
import LocalStorage from "../../../util/localStorage";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PartsInfoDialog from "../../../common/components/modal/parts-info-dialog";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import Buttons from "../../../common/components/buttons";

export default function PartsTab({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const thirdResource = useSelector((state) => state.thirdResource);
    const isLoading = getProp(resource, "isLoading", true);

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());
    const query = FieldsManager.getFieldKeyValues(queryFields);
    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [resourceErrorMessage, setResourceErrorMessage] = useState(false);

    /** UI events
     ================================================================= */

    const handleEditResourceClick = (item) => {

        setIsResourceDialogOpen(true);
        setResourceErrorMessage(false);
        setSelectedItem(item);

    }

    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setSelectedItem({});
    }

    const handleDeletePartClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeletePartClick(item),
            type: "danger",
            content: translate('message.confirm_archive_generic') + ` ${item.ProductService}?`,
            title: translate("text.confirm_delete"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeletePartClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({}, query, {ProductServiceID: item.ProductServiceID}),
            notificationMessage: translate(`message.archived_generic`, [item.ProductService]),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate(`message.archived_generic`, [item.ProductService]),
            errorMessage: true
        }));

        closeConfirmDialog();
    }
    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    function getFields(item = {}) {
        const fieldTemplates = {
            ProductService: new Field('ProductService', '', ['empty'], false, 'text'),
            SKU: new Field('SKU', '', [''], false, 'text'),
            OEMNumber: new Field('OEMNumber', '', [''], false, 'text'),
            CrossRefNumber: new Field('CrossRefNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', ['empty'], false, 'select-search'),
            ProductServicePartLocation: new Field('ProductServicePartLocation', '', [''], false, 'text'),
            UomID: new Field('UomID', '', [''], false, 'select', {}, {
                values: getLookup('Uom')
            }),
            ManufacturerID: new Field('ManufacturerID', '', [], false, 'select-search'),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)


        if (!!item.CrossRefNumber && typeof item.CrossRefNumber === 'string') {
            filledFields.CrossRefNumber.value = item.CrossRefNumber.split(",").reduce((memo, it) => {
                memo.push({label: it, value: it})
                return memo;
            }, []);
        }

        return filledFields
    }


    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <ResourceList
                excludeActiveFilters={['ProductServiceTypeID', 'limit', 'offset', 'sort', 'sortBy']}
                fields={getFields({}, translate)}

                resource={resource}
                fetchData={fetchData}
                isLoading={isLoading}
                queryFields={queryFields}
                setQueryFields={setQueryFields}
                listPath={getListPath()}
                onRefreshTable={() => fetchData(dispatch, query)}
                dispatch={dispatch}
                query={query}

                translate={translate}
                onMount={fetchData}
                selects={getSelects()}

                onRowClick={handleEditResourceClick}
                onEdit={handleEditResourceClick}
                onDelete={handleDeletePartClick}

                afterFiltersHtml={
                    <Buttons
                        buttons={[
                            {
                                label: translate("btn.create"),
                                type: "primary",
                                onClick: handleCreateResourceClick
                            }
                        ]}
                    />
                }
            />

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}

                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            >
                {confirmDialogData?.content}
            </ModalConfirm>

            {isResourceDialogOpen && (
                <PartsInfoDialog
                    query={query}
                    editedItem={selectedItem}
                    onClose={() => setIsResourceDialogOpen(false)}
                    translate={translate}
                    thirdResource={thirdResource}
                    dispatch={dispatch}
                />
            )}
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getResourcePath = () => Resources.WorkOrderProductsServices;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query,

    }))

}

const getQueryFields = () => {
    return {
        query: new Field('query', '', [''], false, 'search', {}, {}),
        offset: new Field('offset', '0', [''], false, 'hidden', {}, {}),
        sortBy: new Field('sortBy', 'ProductService', [''], false, 'hidden', {}, {}),
        sort: new Field('sort', 'ASC', [''], false, 'hidden', {}, {}),
        ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], false, 'select-search', {}, {
            isClearable: true
        }),
        archived: new Field('archived', '', [''], false, 'checkbox'),
        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
    }
}


function getSelects() {
    return {}
}
